<template>
  <v-container>
    <v-row v-if="employeeView" class="my-1">
      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="app-color-bg white--text ml-2 mr-2 mb-4"
            v-bind="attrs"
            v-on="on"
          >
            {{ employeeCompany }}
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="company in companies"
            @click="changeCompany(company)"
            :key="company"
            link
          >
            <v-list-item-title>{{ company }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-card elevation="0">
      <v-row>
        <v-col cols="6" lg="3" class="center mr-lg-1">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDateFormatted"
                label="Start Date"
                hint="MM/DD/YYYY format"
                v-mask="'##/##/####'"
                persistent-hint
                prepend-icon="mdi-calendar"
                clearable
                v-bind="attrs"
                @blur="date = parseDate(startDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6" lg="3" class="center ml-lg-1">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDateFormatted"
                label="End Date"
                hint="MM/DD/YYYY format"
                v-mask="'##/##/####'"
                persistent-hint
                clearable
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="date = parseDate(endDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="center mt-3" elevation="0" :width="width">
      <pie-chart :options="options" :chartData="chartData"></pie-chart>
    </v-card>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import PieChart from "./PieChart";
import { changeCompanyColor, formatDate, parseDate } from "../utils/utils";
const moment = require("moment");
const _ = require("lodash");

export default {
  components: {
    PieChart,
  },
  computed: {
    /**
     * Gets the screen width based on device used.
     */
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 700;
        case "sm":
          return 700;
        case "md":
          return 700;
        case "lg":
        case "xl":
          return 700;
      }
      return 500;
    },
  },
  created() {
    this.employeeCompany = this.$store.state.preferences.firstCompany;
    this.changeCompanyColor(this.employeeCompany);
  },
  data() {
    return {
      companies: ["Martins", "4 Brothers"],
      chartData: {},
      colors: [],
      dataArr: [],
      endDate: "",
      endDateFormatted: "",
      employeeCompany: "",
      labelArr: [],
      menu1: false,
      menu2: false,
      options: {},
      startDate: "",
      startDateFormatted: "",
      typesArr: [],
    };
  },
  directives: { mask },
  mounted() {
    this.setData(false);
    this.fillData();
  },
  methods: {
    /**
     * Changes the company name and fills out the chart based on the company data.
     * @param company - The new active company
     */
    changeCompany(company) {
      this.employeeCompany = company;
      this.changeCompanyColor(this.employeeCompany);
      this.setData(false);
      this.fillData();
    },
    changeCompanyColor: changeCompanyColor,
    /**
     * Checks if the row date is between the user given time frame.
     * @param date - The date to check
     * @returns True if the given date is on or between the user specified time frame.
     */
    dateInTimeFrame(date) {
      let start = moment(this.parseDate(this.startDateFormatted));
      let end = moment(this.parseDate(this.endDateFormatted));
      let mDate = moment(this.parseDate(date));
      return (
        start.subtract(1, "d").isBefore(mDate) && end.add(1, "d").isAfter(mDate)
      );
    },
    /**
     * Inserts all relevant data for the pie chart.
     */
    fillData() {
      this.options = {};
      this.chartData = {};
      if (this.dataArr.length === 0) {
        this.labelArr.push("No data");
        this.dataArr.push(0);
        this.colors = ["grey"];
      } else {
        this.colors = [
          "blue",
          "red",
          "green",
          "purple",
          "yellow",
          "orange",
          "pink",
          "brown",
          "aqua",
          "teal",
          "maroon",
        ];
        let amount = this.labelArr.length - this.colors.length;
        if (amount > 0) {
          for (let i = 0; i < amount; i++) {
            this.colors.push(
              "#" + Math.floor(Math.random() * 16777215).toString(16)
            );
          }
        }
      }
      this.chartData = {
        labels: this.labelArr,
        datasets: [
          {
            backgroundColor: this.colors,
            data: this.dataArr,
          },
        ],
      };
      this.options = {
        title: {
          display: true,
          text: this.getTitle(),
          fontSize: 15,
        },
        tooltips: {
          enabled: true,
        },
      };
    },
    /**
     * Gets the title for the chart based on the hours and time frame.
     * @returns The title for the chart
     */
    getTitle() {
      let title = "";
      let total = this.dataArr.reduce((a, b) => a + b, 0);
      let validTimeFrame = this.isValidTimeFrame();
      if (validTimeFrame) {
        title = `Job/Work Type Hours on or Between ${this.startDateFormatted} and ${this.endDateFormatted} (${total} Total Hours)`;
      } else {
        title = `All Job/Work Type Hours (${total} Total Hours)`;
      }
      return title;
    },
    formatDate,
    parseDate,
    /**
     * Checks if the user specified dates are valid or not.
     * @returns True if the dates are valid
     */
    isValidTimeFrame() {
      let edate = this.parseDate(this.endDateFormatted);
      let sdate = this.parseDate(this.startDateFormatted);
      return (
        moment(edate, "YYYY-MM-DD", true).isValid() &&
        moment(sdate, "YYYY-MM-DD", true).isValid()
      );
    },
    /**
     * Resets all of the relevant chart data.
     */
    resetData() {
      this.labelArr = [];
      this.dataArr = [];
      this.typesArr = [];
    },
    /**
     * Starts the process of gathering all of the data for the pie chart based on the employee. If the user is all, then all users' data will be added to the chart.
     * @param isTimeFrame - True if a valid time frame is specified.
     */
    setData(isTimeFrame) {
      this.resetData();
      // this.user can be all users or just one
      if (Array.isArray(this.user)) {
        _.forEach(this.user, (user) => {
          if (user.firstName !== "All") {
            this.updateChart(user, isTimeFrame);
          }
        });
      } else {
        this.updateChart(this.user, isTimeFrame);
      }
      _.forEach(this.typesArr, (typeElement) => {
        this.dataArr.push(typeElement.total);
        this.labelArr.push(typeElement.type);
      });
    },
    /**
     * Gathers all data for the chart and organizes it based on type and total hours.
     * @param user - The user to gather data from
     * @param isTimeFrame - True if a valid time frame is specified.
     */
    updateChart(user, isTimeFrame) {
      let cIndex = -1;
      if (this.employeeView) {
        cIndex = user.workdays.findIndex(
          (company) => company.company === this.employeeCompany
        );
      } else {
        cIndex = user.workdays.findIndex(
          (company) => company.company === this.company
        );
      }
      if (cIndex !== -1) {
        _.forEach(user.workdays[cIndex].hours, (workday) => {
          if ("type" in workday) {
            let tIndex = this.typesArr.findIndex(
              (typeElement) => workday.type.trim() === typeElement.type.trim()
            );
            if (
              !isTimeFrame ||
              (isTimeFrame && this.dateInTimeFrame(workday.date))
            ) {
              if (tIndex !== -1) {
                this.typesArr[tIndex].total += workday.total;
              } else {
                this.typesArr.push({
                  type: workday.type,
                  total: workday.total,
                });
              }
            }
          }
        });
      }
    },
  },
  props: ["employeeView", "company", "user"],
  watch: {
    /**
     * When the company changes, the data is immediately updated based on the company name.
     */
    company() {
      if (this.isValidTimeFrame()) {
        this.setData(true);
      } else {
        this.setData(false);
      }
      this.fillData();
    },
    /**
     * Formats the date from YYYY-MM-DD to MM/DD/YYYY
     */
    endDate() {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    /**
     * When both formatted dates are valid, the chart will updated with data relevant to the date range.
     */
    endDateFormatted() {
      if (this.isValidTimeFrame()) {
        this.setData(true);
        this.fillData();
      } else if (
        _.isEmpty(this.startDateFormatted) &&
        _.isEmpty(this.endDateFormatted)
      ) {
        this.setData(false);
        this.fillData();
      }
    },
    /**
     * Formats the date from YYYY-MM-DD to MM/DD/YYYY
     */
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    /**
     * When both formatted dates are valid, the chart will updated with data relevant to the date range.
     */
    startDateFormatted() {
      if (this.isValidTimeFrame()) {
        this.setData(true);
        this.fillData();
      } else if (
        _.isEmpty(this.startDateFormatted) &&
        _.isEmpty(this.endDateFormatted)
      ) {
        this.setData(false);
        this.fillData();
      }
    },
    /**
     * Updates the chart based on the current use selected.
     */
    user() {
      if (this.isValidTimeFrame()) {
        this.setData(true);
      } else {
        this.setData(false);
      }
      this.fillData();
    },
  },
};
</script>

<style>
.center {
  margin: 0 auto;
}
</style>
