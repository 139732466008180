<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :content-class="dialogHeight"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <v-tooltip v-if="operation === 'Add Full'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon left color="primary" dark v-bind="attrs" v-on="on">
                mdi-information
              </v-icon>
            </template>
            <span>Adds 5 workdays starting from the entered date</span>
          </v-tooltip>
          <span v-if="operation !== 'Add Full'" class="text-h5"
            >{{ operation }} {{ company }} Workday</span
          >
          <span v-else class="text-h5"
            >{{ operation }} {{ company }} Workweek</span
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        label="Date"
                        v-mask="'##/##/####'"
                        hint="MM/DD/YYYY format"
                        persistent-hint
                        readonly
                        :rules="dateRules"
                        v-bind="attrs"
                        v-on="on"
                        @blur="rowDataCopy.date = parseDate(dateFormatted)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-combobox
                    v-model="rowDataCopy.type"
                    :items="typeItems"
                    label="Type/Job"
                    persistent-hint
                    hint="Type of Work or Job"
                    @input.native="rowDataCopy.type = $event.srcElement.value"
                  ></v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="rowDataCopy.startTime"
                    label="Start Time"
                    persistent-hint
                    hint="Military time"
                    :disabled="
                      isEmpty(rowDataCopy.startTime) &&
                      isEmpty(rowDataCopy.endTime) &&
                      rowDataCopy.total !== undefined
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="rowDataCopy.endTime"
                    label="End Time"
                    persistent-hint
                    hint="Military time"
                    :disabled="
                      isEmpty(rowDataCopy.startTime) &&
                      isEmpty(rowDataCopy.endTime) &&
                      rowDataCopy.total !== undefined
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.number="rowDataCopy.total"
                    label="Total"
                    persistent-hint
                    hint="# of hours"
                    required
                    :rules="totalRules"
                    :disabled="
                      !isEmpty(rowDataCopy.startTime) ||
                      !isEmpty(rowDataCopy.endTime)
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="operation === 'Edit'"
            color="red"
            text
            :disabled="loading"
            :loading="deleteLoading"
            @click="deleteWorkday"
          >
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-2"
            text
            :disabled="loading"
            @click="cancelDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green accent-4"
            text
            :disabled="loading || !validForm"
            :loading="saveLoading"
            @click="saveWorkday"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  getPayPeriodNum,
  formatDate,
  isEmpty,
  parseDate,
} from "../utils/utils";
import _ from "lodash";

export default {
  created() {
    this.rowDataCopy = _.cloneDeep(this.rowData);
    this.dateFormatted = this.rowDataCopy.date;
    this.rowDataCopy.date = parseDate(this.rowDataCopy.date);
    // True when there is nothing entered in the time frame and something entered for the total field.
    this.onlyTotal =
      this.rowDataCopy.total !== undefined &&
      this.isEmpty(this.rowDataCopy.startTime) &&
      this.isEmpty(this.rowDataCopy.endTime);
    this.setTypeItems();
  },
  data: () => ({
    date: "", // using for watcher from datepicker
    dateFormatted: "", // MM/DD/YYYY
    deleteLoading: false, // delete button
    loading: false, // save/add button
    menu1: false, // datepicker
    menu2: false, // datepicker
    onlyTotal: false, // bool checking if start date and end date are filled out
    rowDataCopy: {},
    saveLoading: false, // save button
    timeRegex: /(([1-9]|([1][0-9]|[2][0-3]))(:[0-5]\d)){1}/, // validates military time
    typeItems: [], // work type combobox dropdown list
    validForm: false, // whole form validity
    dateRules: [
      (v) => !!v || "Date is required, use MM/DD/YYYY format",
      (v) => (v && v.length !== 9) || "Must use MM/DD/YYYY format",
    ],
    totalRules: [
      (v) => !!v || "Total is required",
      (v) => (v && v < 25) || "Total must be less than 25 hours",
    ],
  }),
  computed: {
    /**
     * Formats the date from YYYY-MM-DD to MM/DD/YYYY format
     */
    computedDateFormatted() {
      return this.formatDate(this.rowDataCopy.date);
    },
    /**
     * Formats the dialog based on the screen size.
     */
    dialogHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "workday-dialog";
        default:
          return "";
      }
    },
  },
  directives: { mask },
  methods: {
    /**
     * Calculates the total from the time frame entered in the frame.
     */
    calculateTotal() {
      if (
        this.validTime(this.rowDataCopy.startTime) &&
        this.validTime(this.rowDataCopy.endTime)
      ) {
        let sTime = this.rowDataCopy.startTime.replace(":", "");
        let eTime = this.rowDataCopy.endTime.replace(":", "");
        let sNum = parseInt(sTime) / 100;
        let eNum = parseInt(eTime) / 100;
        let sDec = 0.0;
        let eDec = 0.0;

        if (sNum % 1) {
          // turn something like 8:30 into 0.5 or 8:45 into 0.75 for the decimal
          sDec = parseFloat(((sNum % 1) * 1.6666).toFixed(2));
        }
        if (eNum % 1) {
          // turn something like 8:30 into 0.5 or 8:45 into 0.75 for the decimal
          eDec = parseFloat(((eNum % 1) * 1.6666).toFixed(2));
        }
        this.rowDataCopy.total =
          parseInt(eNum) - parseInt(sNum) + (eDec - sDec);
      } else {
        !this.onlyTotal
          ? (this.rowDataCopy.total = undefined)
          : this.rowDataCopy.total;
      }
      if (this.rowDataCopy.total < 0) {
        // don't allow negative totals
        this.rowDataCopy.total = undefined;
      }
    },
    /**
     * User presses the cancel button. Emits to Hours.Vue
     */
    cancelDialog() {
      this.$emit("cancelDialog");
    },
    /**
     * User presses the delete button. Emits to Hours.Vue
     */
    deleteWorkday() {
      this.loading = true;
      this.deleteLoading = true;
      this.$emit("deleteWorkday");
    },
    formatDate: formatDate,
    parseDate: parseDate,
    isEmpty: isEmpty,
    /**
     * Checks the military time entered from user with the military time regex.
     */
    validTime(time) {
      if (time) {
        let regArr = time.match(this.timeRegex);
        return regArr && time === regArr[0];
      }
      return false;
    },
    /**
     * User presses the save button. Sets the pay period based on the date entered from the user. Emits to Hours.vue
     */
    saveWorkday() {
      if (this.validate()) {
        this.rowDataCopy["payPeriod"] = getPayPeriodNum(
          this.$store.state.startPeriod,
          this.rowDataCopy.date
        );
        this.loading = true;
        this.saveLoading = true;
        this.$emit("saveWorkday", this.rowDataCopy);
      }
    },
    /**
     * Predefined dropdown menu from the work type field for Martins and uses previous entries for 4 Brothers.
     */
    setTypeItems() {
      if (this.company === "Martins") {
        this.typeItems = [
          "Admin",
          "Assembly",
          "Construction",
          "Delivery",
          "Furniture",
        ];
      } else if (this.company === "4 Brothers") {
        for (let i = 0; i < this.workdays.length && i < 40; i++) {
          if (
            this.workdays[i].type !== undefined &&
            this.workdays[i].type !== ""
          ) {
            this.typeItems.push(this.workdays[i].type);
          }
        }
        if (this.typeItems.length > 0) {
          // display up to 4 of the most recently entered items
          let n = this.typeItems.length > 15 ? 15 : this.typeItems.length;
          this.typeItems = [...new Set(this.typeItems)].slice(0, n);
        }
      }
    },
    /**
     * Validates the form fields.
     */
    validate() {
      return this.$refs.form.validate();
    },
  },
  props: ["company", "dialog", "rowData", "operation", "workdays"],
  watch: {
    date: function () {
      this.dateFormatted = this.formatDate(this.date);
      this.rowDataCopy.date = this.parseDate(this.date);
    },
    "rowDataCopy.date": function () {
      // not being registered for some reason so using date instead above.
    },
    "rowDataCopy.startTime": function () {
      this.calculateTotal();
    },
    "rowDataCopy.endTime": function () {
      this.calculateTotal();
    },
    "rowDataCopy.total": function () {
      // the total is sometimes set to a string when a time is not valid
      if (typeof this.rowDataCopy.total === "string") {
        this.rowDataCopy.total = undefined;
      }
    },
  },
};
</script>

<style>
.workday-dialog {
  justify-content: center;
  position: absolute;
  top: 5%;
  width: 95%;
  margin: auto;
}
</style>
