<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :content-class="dialogHeight"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Initial Setup</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="firstName"
                    label="First Name"
                    required
                    :rules="nameRules"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="lastName"
                    label="Last Name"
                    required
                    :rules="nameRules"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="firstCompany"
                    label="Which company would you like to load first?"
                    :items="['Martins', '4 Brothers']"
                    required
                    :rules="nameRules"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green accent-4"
            text
            :disabled="!validForm"
            @click="saveSetup"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  created() {},
  data: () => ({
    validForm: false, // whole form validity
    firstName: "",
    lastName: "",
    firstCompany: "",
    nameRules: [(v) => !!v || "Required field"],
  }),
  computed: {
    /**
     * Deprecated?
     */
    dialogHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "workday-dialog";
        default:
          return "";
      }
    },
  },
  methods: {
    /**
     * Emits to App.vue when the user submits their data.
     */
    saveSetup() {
      if (this.validate()) {
        this.$emit("saveSetup", {
          firstName: this.firstName,
          lastName: this.lastName,
          firstCompany: this.firstCompany,
        });
      }
    },
    /**
     * Validates the form fields.
     */
    validate() {
      return this.$refs.form.validate();
    },
  },
  props: ["dialog"],
};
</script>
