import _ from "lodash";
const moment = require("moment");

/**
 * Changes the root colors based on the company active.
 * @param company - The active company
 */
export function changeCompanyColor(company) {
  if (company === "4 Brothers") {
    document.documentElement.style.setProperty(
      "--app-primary-color",
      "#000392",
      "important"
    );
    document.documentElement.style.setProperty(
      "--app-primary-tint",
      "#000270",
      "important"
    );
  } else {
    document.documentElement.style.setProperty(
      "--app-primary-color",
      "#920029",
      "important"
    );
    document.documentElement.style.setProperty(
      "--app-primary-tint",
      "#720020",
      "important"
    );
  }
}

/**
 * Gets today's date in MM/DD/YYYY format.
 * @returns Today's date
 */
export function getCurrentDate() {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "/" + dd + "/" + yyyy;
  return today;
}

/**
 * Gets the 2 week pay period date range based on the date given.
 * @param startDate - The original start date for the 2 week cycle
 * @param otherDate - The date given to find its pay period range
 * @returns The 2 week pay period range the given date lies in
 */
export function getPayPeriodRange(startDate, otherDate) {
  let periodNum = getPayPeriodNum(startDate, otherDate);
  let periodStart = moment(parseDate(otherDate));
  let tmpStart = moment(parseDate(otherDate));
  let periodEnd = moment(parseDate(otherDate));
  let tmpEnd = moment(parseDate(otherDate));
  while (
    getPayPeriodNum(startDate, tmpStart.subtract(1, "d").format("L")) ===
    periodNum
  ) {
    periodStart = periodStart.subtract(1, "d");
  }
  while (
    getPayPeriodNum(startDate, tmpEnd.add(1, "d").format("L")) === periodNum
  ) {
    periodEnd = periodEnd.add(1, "d");
  }

  return `${periodStart.format("L")} - ${periodEnd.format("L")}`;
}

/**
 * Gets the 2 week pay period number for the given date.
 * @param startDate - The original start date for the 2 week cycle
 * @param otherDate - The date given to find its pay period range
 * @returns The 2 week pay period number the given date lies in
 */
export function getPayPeriodNum(startDate, otherDate) {
  let start = moment(parseDate(startDate));
  let date = moment(parseDate(otherDate));
  return Math.floor(Math.abs(date.diff(start, "d")) / 14);
}

/**
 * Formats the date in MM/DD/YYYY format.
 * @param date - The date to format
 * @returns The date formatted
 */
export function formatDate(date) {
  if (!date) return null;

  const [year, month, day] = date.split("-");
  return `${month}/${day}/${year}`;
}

/**
 * Checks if the value is empty using lodash.
 * @param value - The value to check
 * @returns True if the value is empty
 */
export function isEmpty(value) {
  return _.isEmpty(value);
}

/**
 * For any date that is given in M/D/YYYY format, it will be padded with zeros to match MM/DD/YYYY format.
 * @param date - The date to pad
 * @returns The padded date
 */
export function padDate(date) {
  if (!date) return null;
  if (date.includes("/")) {
    let [month, day, year] = date.split("/");
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    let today = month + "/" + day + "/" + year;
    return today;
  } else {
    return date;
  }
}

/**
 * Parses the date into YYYY-MM-DD format. Useful for Moment.js library.
 * @param date - The date to parse
 * @returns The date parsed
 */
export function parseDate(date) {
  if (!date) return null;

  if (date.includes("/")) {
    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  } else {
    return date;
  }
}
