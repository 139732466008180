import { API } from "aws-amplify";

/**
 * Adds a user to the database when they first register.
 * @param user - The users cognito information
 * @param setupData - The data entered from the initial setup dialog
 * @returns An empty array
 */
export async function addUser(user, setupData) {
  let hours = null;
  await API.post("martinsApi", "/addUser", {
    body: {
      id: user.attributes.sub,
      username: user.username,
      workdays: [],
      role: "employee",
      firstName: setupData.firstName,
      lastName: setupData.lastName,
      email: user.attributes.email,
      phone: user.attributes.phone_number,
      preferences: { firstCompany: setupData.firstCompany },
    },
  })
    .then((result) => {
      hours = JSON.parse(result.body);
    })
    .catch((err) => {
      console.log(err);
    });
  return hours;
}

/**
 * Gets the row of data of the user logged in.
 * @returns The data relevant to the user logged in
 */
export async function getUserInfo() {
  let hours = null;
  await API.get("martinsApi", "/hours", {})
    .then((result) => {
      let res = JSON.parse(result.body);
      if (res.length > 0) {
        hours = JSON.parse(result.body)[0];
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return hours;
}

/**
 * Gets all user rows, only accessible to admins.
 * @returns The data of all users
 */
export async function getAllUsers() {
  let users = [];
  await API.get("martinsApi", "/users", {})
    .then((result) => {
      let res = JSON.parse(result.body);
      if (res.length > 0) {
        users = JSON.parse(result.body);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return users;
}

/**
 * Gets the general information from the infoTable such as authed users, start period, and payment notes.
 * @returns The general information for the website
 */
export async function getGeneralInfo() {
  let users = [];
  await API.get("martinsApi", "/details", {})
    .then((result) => {
      let res = JSON.parse(result.body);
      if (res.length > 0) {
        users = JSON.parse(result.body);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return users;
}

/**
 * Updates the users workdays in the hours table.
 * @param workdays - The array of workdays
 * @param userId - The user ID
 */
export async function updateHours(workdays, userId) {
  await API.put("martinsApi", "/hours", {
    body: {
      userId: userId,
      workdays: workdays,
    },
  })
    .then((result) => {
      workdays = JSON.parse(result.body).workdays;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Updates the general information that was changed in the info table.
 * @param paymentNotes - The note from an admin payment
 * @param payPeriod - The pay period
 */
export async function updatePayPeriod(paymentNotes, payPeriod) {
  await API.put("martinsApi", "/details", {
    body: {
      id: "1000", // forgot partition key in infoTable so I set the paymentNotes column to 1000
      notes: paymentNotes,
      payPeriod: payPeriod,
    },
  })
    .then((result) => {
      return JSON.parse(result.body);
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Updates the list of authenticated users for the website.
 * @param authedUsers - The list of authenticated users.
 */
export async function updateAuthedUsers(authedUsers) {
  await API.put("martinsApi", "/details", {
    body: {
      id: "1000",
      authedUsers: authedUsers,
    },
  })
    .then((result) => {
      console.log(JSON.parse(result.body).authedUsers);
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Updates a user's preferences for loading the app.
 * @param preferences - The users preferences
 */
export async function updatePreferences(preferences) {
  console.log("updatePreferences");
  await API.put("martinsApi", "/hours", {
    body: {
      preferences: preferences,
    },
  })
    .then((result) => {
      preferences = JSON.parse(result.body).preferences;
    })
    .catch((err) => {
      console.log(err);
    });
}
