<template>
  <div id="app">
    <v-app>
      <amplify-authenticator v-if="!signedIn">
        <amplify-sign-out></amplify-sign-out>
      </amplify-authenticator>
      <div v-else>
        <div v-if="!loading">
          <v-system-bar class="app-tint-bg"></v-system-bar>

          <v-app-bar class="app-color-bg" dark>
            <v-app-bar-nav-icon
              v-if="!loading"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-toolbar-title>{{ page }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>

          <v-navigation-drawer
            v-if="!loading && this.$store.state.user"
            id="nav-drawer"
            class="app-color-bg"
            fixed
            v-model="drawer"
            absolute
            temporary
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="white--text text-h5">
                  {{ this.$store.state.user.firstName }}
                  {{ this.$store.state.user.lastName }}
                </v-list-item-title>
                <v-list-item-subtitle class="grey--text text--lighten-2">{{
                  this.$store.state.user.username
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="mx-2" color="white"></v-divider>
            <v-list nav dense>
              <v-list-item-group v-model="group">
                <v-list-item
                  :key="item.title"
                  v-for="item in listItems"
                  @click="changePage(item.title)"
                >
                  <v-list-item-icon>
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="white--text text-body-2">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <template v-slot:append>
              <v-card id="signout-card" color="white ma-2">
                <amplify-authenticator @click="drawer = false">
                  <amplify-sign-out></amplify-sign-out>
                </amplify-authenticator>
              </v-card>
            </template>
          </v-navigation-drawer>
          <SetupInfo
            v-if="setupDialog"
            :dialog="setupDialog"
            @saveSetup="saveSetup"
          />
          <Hours v-else-if="page === 'My Hours'" />
          <Admin v-else-if="page === 'Admin Payment'" />
          <AllEmployeeHours v-else-if="page === 'All Employees Hours'" />
          <EmployeesChart
            v-else-if="page === 'My Statistics'"
            :employeeView="true"
            :user="this.$store.state.user"
          />
        </div>
        <div id="loader-container" v-else>
          <div id="loader"></div>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import Admin from "./views/Admin.vue";
import AllEmployeeHours from "./views/AllEmployeeHours.vue";
import EmployeesChart from "./components/EmployeesChart.vue";
import Hours from "./views/Hours.vue";
import SetupInfo from "./components/SetupInfo.vue";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { changeCompanyColor } from "./utils/utils";
const api = require("./shared/api");
export default {
  components: {
    Admin,
    AllEmployeeHours,
    EmployeesChart,
    Hours,
    SetupInfo,
  },
  data() {
    return {
      user: {},
      signedIn: false,
      publicPath: process.env.BASE_URL,
      drawer: false,
      info: null,
      setupInfo: false,
      setupDialog: false,
      flag: false,
      group: null,
      page: "",
      listItems: [
        { title: "Admin Payment", icon: "mdi-currency-usd" },
        { title: "All Employees Hours", icon: "mdi-account-group" },
        { title: "My Hours", icon: "mdi-book-open-outline" },
        { title: "My Statistics", icon: "mdi-chart-pie" },
      ],
      loading: true,
    };
  },
  created() {
    this.loading = true;
    onAuthUIStateChange((state, user) => {
      if (state === AuthState.SignedIn) {
        // signout button color
        document.documentElement.style.setProperty(
          "--amplify-primary-color",
          "transparent"
        );
        document.documentElement.style.setProperty(
          "--amplify-primary-contrast",
          "#000000"
        );
        this.signedIn = true;
        this.user = user;
      } else {
        // signup page color
        document.documentElement.style.setProperty(
          "--amplify-primary-color",
          "blue"
        );
        document.documentElement.style.setProperty(
          "--amplify-primary-contrast",
          "white"
        );
        this.signedIn = false;
      }
    });
  },
  methods: {
    changeCompanyColor: changeCompanyColor,
    changePage(page) {
      this.page = page;
    },
    isAdmin() {
      return this.$store.state.user.role === "admin";
    },
    isEmployee() {
      return this.$store.state.user.role === "employee";
    },
    async loadData() {
      this.$store.state.user = await api.getUserInfo();
      this.info = await api.getGeneralInfo();
    },
    postSignIn() {
      this.$store.state.workdays = this.$store.state.user.workdays;
      this.$store.state.preferences = this.$store.state.user.preferences;
      this.changeCompanyColor(this.$store.state.preferences.firstCompany);
      if (this.isEmployee()) {
        this.listItems.splice(0, 2);
        this.page = "My Hours";
      } else {
        this.page = "Admin Payment";
      }
      this.loading = false;
    },
    async saveSetup(setupData) {
      this.loading = true;
      this.$store.state.user = await api.addUser(this.user, setupData);
      this.postSignIn();
      this.setupDialog = false;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    async signedIn() {
      if (this.signedIn) {
        await this.loadData();
        this.$store.state.notes = this.info[0].notes;
        this.$store.state.authedUsers = this.info[0].authedUsers;
        this.$store.state.payPeriod = this.info[0].payPeriod;
        this.$store.state.startPeriod = this.info[0].startPeriod;
        if (!this.$store.state.user || !this.$store.state.user.firstName) {
          this.setupDialog = true;
          this.loading = false;
        } else {
          this.postSignIn();
        }
      }
    },
  },
};
</script>

<style>
:root {
  --app-primary-color: #000392;
  --app-primary-tint: #000270;
  --amplify-primary-color: blue;
  --amplify-primary-tint: rgb(53, 107, 255);
  --amplify-primary-shade: lightblue;
  --amplify-primary-shade: lightgray;
}
@keyframes RGB_TEXT {
  from {
    background-color: var(--app-primary-color);
  }
  to {
    background-color: var(--app-primary-color);
  }
}
html {
  overflow: auto !important;
}
#loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #000000; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}
#loader-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#main-card {
  height: 100vh;
}
amplify-button > button {
  min-width: 20px;
}
#nav-drawer {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
.white-bg {
  background-color: white !important;
}
.white-text {
  color: white !important;
}
.app-color-bg {
  background-color: var(--app-primary-color) !important;
  /* animation: RGB_TEXT 10s linear infinite; */
  /* above rule creates smooth transition but causes bug in safari? */
}
.app-tint-bg {
  background-color: var(--app-primary-tint) !important;
  /* animation: RGB_TEXT 10s linear infinite; */
  /* above rule creates smooth transition but causes bug in safari? */
}
.app-color-text {
  color: var(--app-primary-color) !important;
  /* animation: RGB_TEXT 10s linear infinite; */
  /* above rule creates smooth transition but causes bug in safari? */
}
.app-tint-text {
  color: var(--app-primary-tint) !important;
  /* animation: RGB_TEXT 10s linear infinite; */
  /* above rule creates smooth transition but causes bug in safari? */
}
</style>
